





























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class NavTabs extends Vue {
  @Prop({ default: () => ([]) })
  tabs!: {label: string; shortenedLabel: string | null; to: string;}[];
}
