import { render, staticRenderFns } from "./TerritorySelector.vue?vue&type=template&id=d71284f6&scoped=true&"
import script from "./TerritorySelector.vue?vue&type=script&lang=ts&"
export * from "./TerritorySelector.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d71284f6",
  null
  
)

export default component.exports